import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/page/redirect/index.vue")
  },
  {
    path: "/complain",
    name: "complain",
    component: () => import("@/views/page/complain/index.vue")
  },
  {
    path: "/complain/:lang",
    name: "complain-language",
    component: () => import("@/views/page/complain/index.vue")
  },
  {
    path: "/term-and-condition",
    name: "term-and-condition",
    component: () => import("@/views/page/term-and-condition/index.vue")
  },
  {
    path: "/session-expired",
    name: "session-expired",
    component: () => import("@/views/page/error/session-expired.vue")
  },
  {
    path: "/success/:action",
    name: "success",
    component: () => import("@/views/page/landing/index.vue")
  },
  { path: "*", redirect: "/" }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
